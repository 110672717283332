import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { I18n, CustomDatepickerI18n } from '../../directives/custom-datepickerI18n';
import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { UsersService } from 'src/app/services/service.index';

@Component({
  selector: 'app-corridas',
  templateUrl: './corridas.component.html',
  styleUrls: ['./corridas.component.css'],
  providers: [I18n, {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n}]
})
export class CorridasComponent implements OnInit {

  @ViewChild('editModal') editModal: ModalDirective;
  modalRef: BsModalRef;
  modalNotariaRef: BsModalRef;

  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'gray modal-lg'
  };

  configMd = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'gray modal-md'
  };

  from: NgbDateStruct;
  to: NgbDateStruct;
  nombreFilter: string = '';
  claveFilter: string = '';
  creditoFilter: string = '';
  clasiFilter: string = '';
  estatusFilter: string = '';
  lista = [{ID: 1, Fecha: '11/05/2020', Desc: 'Fraccionamieto  1 Manzana 1 lote 1', Cliente: 'Jorge Gutierrez'}];
  model:any;

  conCredito = [{ID: 1, Nombre: 'Todos', Valor: '' }, {ID: 2, Nombre: 'Si', Valor: 'Si' }, {ID: 3, Nombre: 'No', Valor: 'No' }];
  clasificaciones = [{ID: 1, Nombre: 'class 1' }, {ID: 2, Nombre: 'class 2' }];
  estatus = [{ID: 1,  Nombre: 'Todos', Valor: '' }, {ID: 2,  Nombre: 'Activo', Valor: 'Activo' }, {ID: 2,  Nombre: 'Inactivo', Valor: 'Inactivo' }];
  fraccionamietos = [{ID: 1, Nombre: 'Fraccionamiento 1', Valor: '2' }, {ID: 2, Nombre: 'Fraccionamiento 2', Valor: 'Si' }, {ID: 3, Nombre: 'Fraccionamiento 3', Valor: 'No' }];
  manzanas = [{ID: 1, Nombre: 'Manzana 1' }, {ID: 2, Nombre: 'Manzana 2' }];
  clientes = [{ID: 1, Nombre: 'Jorge Gutierrez', Valor: '2' }, {ID: 2, Nombre: 'Hiram Alvarez', Valor: 'Si' }];
  Lotes = [{ID: 1, Nombre: 'lote 1' }, {ID: 2, Nombre: 'lote 2' }];
  cuentas = [{ID: 1, Nombre: '3238623682737' }, {ID: 2, Nombre: '3238623682737' }];
  formas = [{ID: 1, Nombre: 'Efectivo' }, {ID: 2, Nombre: 'Credito' }];
  creditos = [{ID: 1, Nombre: 'Credito 1', Valor: '2' }, {ID: 2, Nombre: 'Credito 1', Valor: 'Si' }];
  tipos = [{ID: 1, Nombre: 'Comercial' }, {ID: 2, Nombre: 'Residencial' }];
  monedas = [{ID: 1,  Nombre: 'Todos', Valor: '' }, {ID: 2,  Nombre: 'MN', Valor: 'MN' }, {ID: 2,  Nombre: 'USD', Valor: 'USD' }];
  constructor(private _userService: UsersService, private modalService: BsModalService, private toastr: ToastrService) { 
    this._userService.loadStorage();
  }

  ngOnInit() {
  }

  onShow(id: number, template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, this.config);
  }

  onDelete(id: number) {
    Swal.fire({
      title: 'Esta seguro?',
      text: 'Esta seguro que quiere cancelar corrida, no se podra revertir!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Eliminar!',
      focusConfirm: false,
      focusCancel: false,
      allowEnterKey: false
    }).then((result) => {
      if (result.value) {
        Swal.fire({
          title: 'Cancelado!',
          text: 'Corrida a sido cancelado con exito.',
          type: 'success',
          confirmButtonText: 'Aceptar'
        });
      }
    });
  }

  onSubmit(FormData) {
    if (FormData.valid) {
      this.toastr.success('Corrida guardado con exito.', 'Guardado!');
      FormData.resetForm();
      this.modalRef.hide();
    }
  }

  onShowNotaria(id: number, template: TemplateRef<any>) {
    this.modalNotariaRef = this.modalService.show(template, this.configMd);
  }

  onSubmitDetalle(FormData: any) {
    this.toastr.success('Notaria guardada con exito.', 'Agregado!');
    this.modalNotariaRef.hide();
  }
}
