export class CuentaBancaria {
  ID: number;
  Descripcion: string;
  Numero: string;
  Clave: string;
  Estatus?: boolean;
  Moneda?: number;
  Empresa_ID?: number;

  constructor() {
    this.ID = 0;
  }
}
