export class Fraccionamiento {
  ID: number;
  Nombre: string;
  InteresAnual?: number;
  Enganche?: number;
  IinteresMoratorio?: number;
  PorcSeguro?: number;
  NoManzanas?: number;
  NoLotes?: number;
  NoLotesVendido?: number;
  Vigente?: boolean;
  Estatus?: boolean;
  Localidad_ID: number;
  Empresa_ID?: number;
  Imagen: string;
  
  constructor() {
    this.ID = 0;
    this.Estatus = true;
    this.Vigente = true;
    this.NoManzanas = 0;
    this.NoLotes = 0;
    this.NoLotesVendido = 0;
  }
}
