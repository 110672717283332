import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { UsuariosComponent } from './usuarios/usuarios.component';
import { TiposUsuarioComponent } from './tipos-usuario/tipos-usuario.component';
import { ClientesComponent } from './clientes/clientes.component';
import { LocalidadesComponent } from './localidades/localidades.component';
import { FraccionamientosComponent } from './fraccionamientos/fraccionamientos.component';
import { ManzanasComponent } from './manzanas/manzanas.component';
import { LotesComponent } from './lotes/lotes.component';
import { FormasPagoComponent } from './formas-pago/formas-pago.component';
import { CuentasComponent } from './cuentas/cuentas.component';
import { NotariasComponent } from './notarias/notarias.component';
import { PagosComponent } from './pagos/pagos.component';
import { ControlCreditosComponent } from './control-creditos/control-creditos.component';
import { CreditosComponent } from './creditos/creditos.component';
import { ControlCreditoFormComponent } from './control-credito-form/control-credito-form.component';
import { CorridasComponent } from './corridas/corridas.component';


const routes: Routes = [
  {
    path: 'dashboard',
    component: DashboardComponent
  },
  {
    path: 'usuarios',
    component: UsuariosComponent
  },
  {
    path: 'tiposusuario',
    component: TiposUsuarioComponent
  },
  {
    path: 'clientes',
    component: ClientesComponent
  },
  {
    path: 'localidades',
    component: LocalidadesComponent
  },
  {
    path: 'fraccionamientos',
    component: FraccionamientosComponent
  },
  {
    path: 'manzanas',
    component: ManzanasComponent
  },
  {
    path: 'lotes',
    component: LotesComponent
  },
  {
    path: 'formaspago',
    component: FormasPagoComponent
  },
  {
    path: 'cuentas',
    component: CuentasComponent
  },
  {
    path: 'notarias',
    component: NotariasComponent
  },
  {
    path: 'pagos',
    component: PagosComponent
  },
  {
    path: 'controlcreditos',
    component: ControlCreditosComponent
  },
  {
    path: 'controlcreditosform',
    component: ControlCreditoFormComponent
  },
  {
    path: 'creditos',
    component: CreditosComponent
  },
  {
    path: 'corridas',
    component: CorridasComponent
  }
  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule { }
